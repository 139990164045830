import React, { useState } from 'react';
import '@fontsource/roboto-slab';
import '@fontsource/roboto-slab/700.css';
import logo from './logo.svg';
import './App.css';

import { apiServer } from './utils/api'


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Geoffrey Angus</h1>
        <div className="LinkBanner">
        <a className="App-link" href="https://geoffreyangus.beehiiv.com/" target="_blank" rel="noopener noreferrer">Blog</a>
          <a className="App-link" href="https://github.com/geoffreyangus" target="_blank" rel="noopener noreferrer">GitHub</a>
          <a className="App-link" href="https://twitter.com/geoffreyangus" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a className="App-link" href="https://www.linkedin.com/in/geoffreyangus" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </div>
      </header>
    </div>
  );
}

export default App;
